import { Box, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, Typography } from "@mui/material"
import Text from "../../../assets/components/Text"
import BasicButton from "../../../assets/components/BasicButton"
import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from "react"
import { Customer, Instrument, Song } from "../../../utils/types"
import { getCustomerDataFromId, getCustomerInstruments, getSongPackagesFromCustomer, updateCustomerInstruments, updateCustomerSongPackages } from "../../../firebase/databaseApi"
import CloseButton from "../../../assets/components/CloseButton"
import { AddOutlined, SaveOutlined } from "@mui/icons-material"

interface Row extends Customer {
    isNew?: boolean
    [key: string]: any;
  }

interface EditInstrumentsDialogProps {
    open: boolean;
    closeDialog: () => void;
    setRows: Dispatch<SetStateAction<Row[]>>;
    rows: Row[];
    customerId: string | null;
}

const EditInstrumentsDialog = (props: EditInstrumentsDialogProps) => {
    const { open, setRows, rows, customerId } = props;
    const [instruments, setInstruments] = useState<Instrument[]>([])
    const [newInstrument, setNewInstrument] = useState<Instrument>({deviceId: "", type: "", serialNr: ""});
    const [customer, setCustomer] = useState<Customer | undefined>();

    useEffect(() => {
        if (open) {
            // Fetch current song packages for customer
            fetchInstrumentsForCustomer()

            // Fetch customer data from customer id
            fetchCustomerData()
        }
    }, [open])

    const fetchInstrumentsForCustomer = async () => {
        if (customerId) {
            setInstruments(await getCustomerInstruments(customerId))
        }
    }

    const fetchCustomerData = async () => {
        if (customerId) {
            setCustomer(await getCustomerDataFromId(customerId))
        }
    }

    const handleAddInstrument = (event: FormEvent) => {
        event.preventDefault()

        // TODO: add validation, e.g. that an instrument exists

        // Add instrument
        const newInstruments = [...instruments]
        if (newInstrument.deviceId && newInstrument.type) {
            newInstruments.push(newInstrument)
        }
        setInstruments(newInstruments)
        setNewInstrument({deviceId: "", type: "", serialNr: ""})
    }

    const handleRemoveInstrument = (deviceId: string) => {
        const newInstruments = [...instruments]
        const index = newInstruments.findIndex((instrument) => instrument.deviceId === deviceId);
        if (index > -1) { // only splice array when item is found
            newInstruments.splice(index, 1); // 2nd parameter means remove one item only
        }
        setInstruments(newInstruments)
    }

    const handleSave = async () => {
        try {
            if (!customerId) {
                throw Error("Customer id is undefined.")
            }

            // Save to database
            await updateCustomerInstruments(customerId, instruments)

            // Update table visually to reflect change
            const prevRow = rows.find((row) => row.id === customerId);
            const updatedRow: Partial<Row> = { ...prevRow, instruments: instruments };
            setRows(rows.map((row) => (
                (row.id === customerId) ? 
                {...row, ...updatedRow} 
                : row
            )));

            // Close dialog
            props.closeDialog()
        } catch (error) {
            // TODO: Do something with error
        }
    }
  
    return (
        <Dialog
            open={open}
            onClose={props.closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            <Text size="heading" bold>
                {`Redigera instrument för ${customer?.alias || 'NaN'}`}
            </Text>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "10px",
                        width: "100%",
                    }}
                >
                    <form onSubmit={handleAddInstrument}>
                        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                            <TextField
                                fullWidth
                                variant="standard"
                                label="Enhetens ID"
                                value={newInstrument.deviceId}
                                onChange={(event) => setNewInstrument({...newInstrument, deviceId: event.target.value})}
                            />
                            <TextField
                                select
                                fullWidth
                                variant="standard"
                                label="Typ"
                                value={newInstrument.type}
                                onChange={(event) => setNewInstrument({...newInstrument, type: event.target.value})}
                            >
                                <MenuItem value="buttons">Buttons</MenuItem>
                                <MenuItem value="touch">Touch</MenuItem>
                                <MenuItem value="joysticks">Joysticks</MenuItem>
                                <MenuItem value="levers">Levers</MenuItem>
                                <MenuItem hidden value={undefined}>
                                    None
                                </MenuItem>
                            </TextField>
                            <BasicButton 
                                type="submit" 
                                title="Lägg till instrument" 
                                onClick={handleAddInstrument} 
                                icon={AddOutlined}
                                fullWidth />
                        </Box>
                    </form>

                    {instruments.map((instrument, index) => (
                        <Box key={index} sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                            <TextField
                                fullWidth
                                id={`instrument.${index}`}
                                name={`instrument.${index}`}
                                variant="standard"
                                label="Enhets-ID"
                                value={instrument.deviceId}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <TextField
                                fullWidth
                                id={`instrumenttyp.${index}`}
                                name={`instrumenttyp.${index}`}
                                variant="standard"
                                label="Typ"
                                value={instrument.type}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <CloseButton title="Ta bort" onClick={() => handleRemoveInstrument(instrument.deviceId)} disabled={false} />
                        </Box>
                    ))}
                </Box>

            </DialogContent>
            <DialogActions>
                <BasicButton title="Spara" icon={SaveOutlined} onClick={handleSave} />
                <BasicButton title="Avbryt" onClick={props.closeDialog} />
            </DialogActions>
        </Dialog>
    );
}

export default EditInstrumentsDialog