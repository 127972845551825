import "./App.css"
import { BrowserRouter as Router } from "react-router-dom"
import {
  CurrentUserProvider,
  useCurrentUser
} from "./hooks/contexts/currentUserContext"
import { CurrentSongProvider } from "./hooks/contexts/currentSongContext"
import { MqttHandlerProvider } from "./mqtt/mqttHandlerContext"
import Loading from "./pages/Loading"
import ContentsLoggedIn from "./components/ContentsLoggedIn"
import ContentsNoUser from "./components/ContentsNoUser"
import { CurrentLocationProvider } from "./hooks/contexts/currentLocationContext"
import { ThemeProvider } from "@mui/material"
import { theme } from "./theme"

const AppContents = () => {
  const currentUser = useCurrentUser()

  return (
    <>
      {currentUser !== undefined ? (
        <>
          {currentUser ? (
            <MqttHandlerProvider>
              <CurrentSongProvider>
                <CurrentLocationProvider>
                  <ContentsLoggedIn />
                </CurrentLocationProvider>
              </CurrentSongProvider>
            </MqttHandlerProvider>
          ) : (
            <ContentsNoUser />
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  )
}

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CurrentUserProvider>
          <AppContents />
        </CurrentUserProvider>
      </ThemeProvider>
    </Router>
  )
}

export default App
