import { Box, Divider, Grid, Stack } from "@mui/material"
import { useIsSmallScreen } from "../../hooks/useIsSmallScreen"
import { AdminPanelSettingsOutlined, CottageOutlined, LoginRounded, SettingsOutlined, GraphicEq, MusicNote, Lyrics } from "@mui/icons-material"

import Text from "./Text"
import RouteButton from "./RouteButton"
import { ROUTES } from "../../utils/api"
import { useCurrentUser } from "../../hooks/contexts/currentUserContext"
import { Logo } from "./Logo"
import NowPlaying from "./NowPlaying"

interface MenuWithContentProps {
    menuItems?: React.ReactNode
    content: React.ReactNode
    showCurrentlyPlaying?: boolean
    extraPadding?: boolean
    heading?: string
}

const MenuWithContent = ({ menuItems, content, showCurrentlyPlaying, heading }: MenuWithContentProps) => {
    const isSmallScreen = useIsSmallScreen()
    return isSmallScreen ? (
        <MobileMenu menuItems={menuItems} content={content} showCurrentlyPlaying={showCurrentlyPlaying} heading={heading} />
    ) : (
        <DesktopMenu menuItems={menuItems} content={content} showCurrentlyPlaying={showCurrentlyPlaying} />
    )
}

const MobileMenu = ({ menuItems, content, heading }: MenuWithContentProps) => {
    return (
        <Grid container direction="column" sx={{ height: "90%" }}>
            <Grid
                item
                sx={{
                    margin: 1,
                }}
            >
                {heading && (
                    <Text noWrap size={25}>
                        {heading}
                    </Text>
                )}
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 1, pb: 2, px: 1 }}>{menuItems}</Box>
            </Grid>

            <Grid item xs sx={{ overflow: "auto", height: "80%", padding: "0px 0px 5rem 0px" }}>
                {content}
            </Grid>
        </Grid>
    )
}

const DesktopMenu = ({ menuItems, content, showCurrentlyPlaying }: MenuWithContentProps) => {
    const currentUser = useCurrentUser()
    return (
        <>
            <Box sx={{ position: "absolute", top: 34, left: 0, right: 0, textAlign: "center" }}>
                <h3> Välkommen{currentUser ? ` ${currentUser?.auth.displayName}` : ""}!</h3>
            </Box>
            <Box sx={{ position: "absolute", top: 34, right: 26 }}>
                <RouteButton inHeader route={ROUTES.SETTINGS.MAIN} title="Inställningar" icon={SettingsOutlined} />
            </Box>

            <Grid container sx={{ height: "100%" }}>
                <Grid
                    item
                    sx={{
                        minWidth: "150px",
                        padding: "32px 24px",
                        display: "flex",
                        flexDirection: "column",
                        position: "absolute",
                        height: "100vh",
                    }}
                >
                    <Stack sx={{ height: "100%", gap: 2 }}>
                        <Box>
                            <Logo />
                        </Box>
                        {currentUser?.customer && (
                            <>
                                <Divider orientation="horizontal" sx={{ borderColor: "#808080" }} />
                                <RouteButton inHeader route={ROUTES.HOME} title="Hem" icon={CottageOutlined} />
                                <Divider orientation="horizontal" sx={{ borderColor: "#808080" }} />
                                {/* <RouteButton inHeader route={ROUTES.LIBRARY.MUSIC.fullPath} title="Bibliotek" icon={LibraryMusicOutlined} /> */}
                                <Box sx={{ display: "flex" }}>
                                    <Text size="heading" bold>
                                        Bibliotek
                                    </Text>
                                </Box>
                                <RouteButton title="Musik" route={ROUTES.LIBRARY.MUSIC.fullPath} icon={MusicNote} />
                                <RouteButton title="Ljudlandskap" route={ROUTES.LIBRARY.SOUNDBANKS.fullPath} icon={GraphicEq} />
                                <RouteButton title="Tonarter" route={ROUTES.LIBRARY.KEY.fullPath} icon={Lyrics} />

                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "10px" }}>{menuItems}</Box>
                            </>
                        )}

                        <Grid item xs sx={{ overflow: "auto" }}></Grid>
                        {currentUser ? (
                            <>
                                <Box sx={{ position: "absolute", bottom: 24 }}>
                                    <NowPlaying />
                                </Box>
                                {!currentUser.customer && <RouteButton inHeader route={ROUTES.ACTIVATE_ACCOUNT} title="Aktivera konto" />}
                            </>
                        ) : (
                            <RouteButton inHeader route={ROUTES.LOG_IN} title="Logga in" icon={LoginRounded} />
                        )}
                    </Stack>
                </Grid>
                <Grid item xs sx={{ overflow: "auto", height: "100%", padding: "5rem 2rem 0rem 2rem" }}>
                    {content}
                </Grid>
            </Grid>
        </>
    )
}

export default MenuWithContent
