import { ReactNode, useEffect, useState } from "react"
import Instruments from "./Components/Instruments"
import Section from "../../components/Section"
import MasterVolume from "./Components/MasterVolume"
import MenuWithContent from "../../assets/components/MenuWithContent"
import { useCurrentSong } from "../../hooks/contexts/currentSongContext"
import { useChangeCurrentLocation } from "../../hooks/contexts/currentLocationContext"
import { Box, Container, SwipeableDrawer, Typography } from "@mui/material"
import { KeyboardArrowDown } from "@mui/icons-material"
import Text from "../../assets/components/Text"
import BasicButton from "../../assets/components/BasicButton"
import { useIsSmallScreen } from "../../hooks/useIsSmallScreen"
import AudioPlayer from "../../components/audioplayer/AudioPlayer"
import { useCurrentUser } from "../../hooks/contexts/currentUserContext"
import TuneIcon from "@mui/icons-material/Tune"

const Welcome = () => {
    const currentSong = useCurrentSong()
    const changeCurrentLocation = useChangeCurrentLocation()
    const isSmallScreen = useIsSmallScreen()
    const user = useCurrentUser()

    const [isOpen, setIsOpen] = useState(false)

    const toggleDrawer = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        changeCurrentLocation("Hem")
    }, [changeCurrentLocation])

    const [instrumentVolumes, setInstrumentVolumes] = useState<{
        [key: string]: number
    }>({})

    return (
        <MenuWithContent
            showCurrentlyPlaying
            menuItems={<></>}
            content={
                <Container
                    sx={{
                        paddingTop: isSmallScreen ? 1 : 3,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "100%",
                        backgroundColor: "lightred",
                    }}
                >
                    <Instruments currentSong={currentSong} instrumentVolumes={instrumentVolumes} setInstrumentVolumes={setInstrumentVolumes} />
                    {isSmallScreen ? (
                        <Box sx={{ display: "flex", position: "absolute", bottom: 60 }}>
                            <BasicButton fullWidth={isSmallScreen} title="Öppna Mixer" onClick={toggleDrawer} icon={TuneIcon} variant="contained" />
                            <Drawer isOpen={isOpen} toggleDrawer={toggleDrawer} heading="Mixer">
                                <MasterVolume instrumentVolumes={instrumentVolumes} setInstrumentVolumes={setInstrumentVolumes} isSmallScreen={isSmallScreen} />
                                {currentSong?.hasSections && <Section />}
                                {currentSong?.backingTrack && <AudioPlayer backingTrack={currentSong.backingTrack} isSmallScreen={isSmallScreen} />}
                            </Drawer>
                        </Box>
                    ) : (
                        <Box sx={{ position: "absolute", bottom: 30, alignitems: "center", paddingLeft: 5, width: "85%" }}>
                            {currentSong?.backingTrack && <AudioPlayer backingTrack={currentSong.backingTrack} />}
                        </Box>
                    )}
                </Container>
            }
        />
    )
}

interface IDrawer {
    isOpen: boolean
    toggleDrawer: () => void
    children: ReactNode
    heading?: string
    variant?: "bottom" | "left" | "right" | "top" | undefined
}

export const Drawer = ({ isOpen, toggleDrawer, children, heading, variant = "bottom" }: IDrawer) => {
    return (
        <SwipeableDrawer
            anchor={variant}
            open={isOpen}
            onClose={toggleDrawer}
            onOpen={toggleDrawer}
            sx={{
                "& .MuiDrawer-paper": {
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                },
            }}
        >
            <Box sx={{ height: "auto" }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <KeyboardArrowDown onClick={toggleDrawer} />
                    {heading && <Text size="giant">{heading}</Text>}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 5,
                        pb: 5,
                    }}
                >
                    {children}
                </Box>
            </Box>
        </SwipeableDrawer>
    )
}

export default Welcome
