// import { useCurrentUser } from "../hooks/contexts/currentUserContext"
import { logOut } from "../firebase/authenticationApi"
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom"
import RouteButton from "../assets/components/RouteButton"
import { ROUTES } from "../utils/api"
import BasicButton from "../assets/components/BasicButton"
import { AccountCircleOutlined, LogoutOutlined, LoyaltyOutlined, HelpOutline, AdminPanelSettingsOutlined } from "@mui/icons-material"
import { useEffect } from "react"
import Account from "./settings_subpages/Account"
import License from "./settings_subpages/License"
import About from "./About"
import MenuWithContent from "../assets/components/MenuWithContent"
import { useChangeCurrentLocation } from "../hooks/contexts/currentLocationContext"
import { useIsSmallScreen } from "../hooks/useIsSmallScreen"
import { Box, Container, Divider } from "@mui/material"
import { useCurrentUser } from "../hooks/contexts/currentUserContext"

const Settings = () => {
    const currentUser = useCurrentUser()
    const navigate = useNavigate()
    const curPath = useLocation().pathname
    const isSmallScreen = useIsSmallScreen()

    const changeCurrentLocation = useChangeCurrentLocation()

    useEffect(() => {
        if (curPath === ROUTES.SETTINGS.MAIN) {
            // Default subpage in settings page
            navigate(ROUTES.SETTINGS.ACCOUNT.fullPath, { replace: true })
        }
    }, [curPath])

    useEffect(() => {
        changeCurrentLocation("Inställningar")
    }, [changeCurrentLocation])

    const generalRoutes = (
        <>
            <RouteButton title="Konto" route={ROUTES.SETTINGS.ACCOUNT.fullPath} icon={AccountCircleOutlined} />
            <RouteButton title="Licens" route={ROUTES.SETTINGS.LICENSE.fullPath} icon={LoyaltyOutlined} />
            <RouteButton title="Om" route={ROUTES.SETTINGS.ABOUT.fullPath} icon={HelpOutline} />
        </>
    )

    return (
        <MenuWithContent
            showCurrentlyPlaying
            extraPadding
            menuItems={
                isSmallScreen && (
                    <>
                        {generalRoutes}
                        {currentUser?.admin && <RouteButton inHeader route={ROUTES.ADMIN.MAIN} title="Admin" icon={AdminPanelSettingsOutlined} />}
                    </>
                )
            }
            content={
				<Container
					sx={{
						paddingTop: isSmallScreen ? 1 : 3,
						display: "flex",
						flexDirection: "column",
						minHeight: "50vh",
						marginLeft: isSmallScreen ? 0 : "200px"
					}}
				>
				
                    {!isSmallScreen && (
                        <>
                            <Box sx={{ display: "flex", gap: "10px", flexDirection: "row" }}>
                                {generalRoutes}
                                {currentUser?.admin && <RouteButton inHeader route={ROUTES.ADMIN.MAIN} title="Admin" icon={AdminPanelSettingsOutlined} />}
                                <BasicButton title="Logga ut" onClick={logOut} icon={LogoutOutlined}  />
                            </Box>
                            <Divider orientation="horizontal" sx={{ borderColor: "#808080", my: 2 }} />
                        </>
                    )}

                    <Routes>
                        <Route path={ROUTES.SETTINGS.MAIN} element={<Navigate to={ROUTES.SETTINGS.ACCOUNT.fullPath} replace />} />
                        <Route path={ROUTES.SETTINGS.ACCOUNT.subPath} element={<Account />} />
                        <Route path={ROUTES.SETTINGS.ABOUT.subPath} element={<About />} />
                        <Route path={ROUTES.SETTINGS.LICENSE.subPath} element={<License />} />
                    </Routes>
                </Container>
            }
        />
    )
}

export default Settings
