import { useEffect, useRef, useState } from "react"
import { Box, Button, Divider, Slider } from "@mui/material"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import PauseIcon from "@mui/icons-material/Pause"
import VolumeUpIcon from "@mui/icons-material/VolumeUp"
import { BackingTrack } from "../../utils/types"
import Text from "../../assets/components/Text"

interface AudioPlayer {
    backingTrack: BackingTrack
    isSmallScreen?: boolean
}

const AudioPlayer = ({ backingTrack, isSmallScreen = false }: AudioPlayer) => {
    const audioRef = useRef<HTMLAudioElement>(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const [progress, setProgress] = useState(0)
    const [volume, setVolume] = useState(100)
    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.addEventListener("loadedmetadata", () => {
                setDuration(audioRef.current?.duration || 0)
            })
        }
    }, [])

    const togglePlay = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause()
            } else {
                audioRef.current.play()
            }
            setIsPlaying(!isPlaying)
        }
    }

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60)
        const seconds = Math.floor(time % 60)
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
    }

    const handleTimeUpdate = () => {
        if (audioRef.current) {
            setCurrentTime(audioRef.current.currentTime)
            setProgress((audioRef.current.currentTime / audioRef.current.duration) * 100)
        }
    }

    const handleVolumeChange = (event: any, newValue: number | number[]) => {
        const newVolume = typeof newValue === "number" ? newValue : newValue[0]
        setVolume(newVolume)
        if (audioRef.current) {
            audioRef.current.volume = newVolume / 100
        }
    }

    const handleProgressChange = (event: any, newValue: number | number[]) => {
        const newProgress = typeof newValue === "number" ? newValue : newValue[0]
        setProgress(newProgress)
        if (audioRef.current) {
            audioRef.current.currentTime = (newProgress / 100) * audioRef.current.duration
        }
    }

    return (
        <Box sx={{ paddingY: 1, gap: 1, display: "flex", width: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Box sx={{ marginLeft: "100px", width: "100%" }}>
                <Divider orientation="horizontal" sx={{ borderColor: "#808080", width: "100%" }} />
            </Box>
            <Box
                sx={{ display: "grid", gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 1fr 1fr", gridTemplateRows: isSmallScreen ? "auto" : "none", justifyItems: isSmallScreen ? "center" : "none" }}
            >
                <Box></Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Button onClick={togglePlay} variant="contained" size="small" sx={{ paddingX: 0, width: "20%", borderRadius: "50px" }}>
                        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                    </Button>
                    <Slider value={progress} onChange={handleProgressChange} sx={{ width: isSmallScreen ? 300 : 400 }} />
                    <Box sx={{ minWidth: "80px", textAlign: "center" }}>
                        {formatTime(currentTime)} / {formatTime(duration)}
                    </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 1, justifyContent: "right", alignItems: "center" }}>
                    <VolumeUpIcon />
                    <Slider value={volume} onChange={handleVolumeChange} sx={{ width: 100 }} />
                </Box>
            </Box>
            <audio ref={audioRef} src={backingTrack.src} onTimeUpdate={handleTimeUpdate} />
        </Box>
    )
}

export default AudioPlayer
