import { useEffect, useState, useMemo } from "react"
import { useSwipeable } from "react-swipeable"
import logo from "../../../assets/funki_VAG_1280_red.png"
import { Grid, Stack, useMediaQuery, ToggleButton, ToggleButtonGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, Box } from "@mui/material"
import { SONG_CATEGORIES, SONG_TYPES, Song } from "../../../utils/types"
import SongCard from "../../../components/Card"
import { useCurrentUser } from "../../../hooks/contexts/currentUserContext"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import GridViewIcon from "@mui/icons-material/GridView"
import { useChangeCurrentSong, useCurrentSong } from "../../../hooks/contexts/currentSongContext"
import DisplayedCards from "./DisplayedCards"

interface ChooseSongProps {
    type: SONG_TYPES
    searchValue?: string
    category?: string | undefined
    keytone?: string | undefined
}

const ChooseSong = ({ type, category, searchValue }: ChooseSongProps) => {
    const currentUser = useCurrentUser()
    const currentSong = useCurrentSong()
    const changeCurrentSong = useChangeCurrentSong()
    const songList = useMemo(() => currentUser?.songList || [], [currentUser])
    const [page, setPage] = useState<number>(1)
    const [filteredSongs, setFilteredSongs] = useState<Song[]>([])
    const [pageCount, setPageCount] = useState<number>(0)
    const [cardsPerPage, setCardPerPage] = useState<number>(0)
    const [viewMode, setViewMode] = useState<"grid" | "list">("grid")

    const [swipeDirection, setSwipeDirection] = useState<"left" | "right" | null>(null)

    let displayedSongs: Song[] = filteredSongs

    const handleSwipe = (direction: "left" | "right") => {
        setSwipeDirection(direction)
        setTimeout(() => {
            setSwipeDirection(null)
        }, 200)
    }

    const swipeHandlers = useSwipeable({
        onSwipedRight: () => {
            if (page !== 1) {
                setPage(page - 1)
                handleSwipe("right")
            }
        },
        onSwipedLeft: () => {
            if (page !== pageCount) {
                setPage(page + 1)
                handleSwipe("left")
            }
        },
        preventScrollOnSwipe: true,
        trackMouse: true,
    })

    const isExtraSmallScreen = useMediaQuery("(max-width:600px)")
    const isSmallScreen = useMediaQuery("(max-width:900px)")
    const isMediumScreen = useMediaQuery("(max-width:1200px)")
    const isLargeScreen = useMediaQuery("(max-width:1536px)")
    const isExtraLargeScreen = useMediaQuery("(min-width:1536px)")

    if (searchValue !== undefined) {
        const formattedSearchValue = searchValue.trim().toLowerCase()
        displayedSongs = filteredSongs.filter((song: Song) => {
            return song.title.toLowerCase().includes(formattedSearchValue)
        })
    }

    useEffect(() => {
        const updatedFilteredSongs = songList
            .slice()
            .filter((song) => {
                return song.type === type
            })
            .filter((song) => {
                return song.type === SONG_TYPES.KEY ? song.keytone === category || category === SONG_CATEGORIES.ALL : song.category === category || category === SONG_CATEGORIES.ALL
            })
        setFilteredSongs(updatedFilteredSongs)
    }, [type, category, songList])

    useEffect(() => {
        const updatedPageCount = Math.max(1, Math.ceil(filteredSongs.length / cardsPerPage))

        if (updatedPageCount) {
            if (page > updatedPageCount) {
                setPage(updatedPageCount)
            }

            setPageCount(updatedPageCount)
        }
    }, [filteredSongs, cardsPerPage, page])

    useEffect(() => {
        const updatedCardsPerPage: number = isExtraSmallScreen ? 6 : isSmallScreen ? 6 : isMediumScreen ? 6 : isLargeScreen ? 9 : isExtraLargeScreen ? 12 : 12
        setCardPerPage(updatedCardsPerPage)
    }, [isExtraSmallScreen, isSmallScreen, isMediumScreen, isLargeScreen, isExtraLargeScreen])

    return (
        <Stack sx={{ height: "100%" }}>
            {type !== SONG_TYPES.KEY && (
                <ToggleButtonGroup value={viewMode} exclusive onChange={(event, newView) => setViewMode(newView)} aria-label="view mode" sx={{ mb: 2 }}>
                    <ToggleButton value="grid" aria-label="grid view">
                        <GridViewIcon />
                    </ToggleButton>
                    <ToggleButton value="list" aria-label="list view">
                        <FormatListBulletedIcon />
                    </ToggleButton>
                </ToggleButtonGroup>
            )}

            <Grid
                item
                xs
                {...swipeHandlers}
                sx={{
                    overflow: "auto",
                    padding: 1,
                    transition: "transform 0.3s ease-in-out",
                    transform: swipeDirection === "left" ? "translateX(-100%)" : swipeDirection === "right" ? "translateX(100%)" : "none",
                }}
            >
                <DisplayedCards
                    displayedSongs={displayedSongs}
                    page={page}
                    cardsPerPage={cardsPerPage}
                    viewMode={viewMode}
                    changeCurrentSong={changeCurrentSong}
                    currentSong={currentSong}
                    type={type}
                />
            </Grid>
        </Stack>
    )
}

export default ChooseSong
